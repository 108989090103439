<template>
  <div class="row">
    <div class="col-12">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <!-- 설비별 점검항목 -->
        <!-- 설비에 대한 점검항목이 없습니다. -->
        <c-table
          ref="table"
          title="설비별 점검항목"
          :columns="grid2.columns"
          :gridHeight="grid2.height"
          :editable="editable && result.checkStatusCd!=='MCSC000015'"
          :data="result.equipmentResultList"
          :merge="grid2.merge"
          :filtering="false"
          :columnSetting="false"
          :isExcelDown="false"
          :usePaging="false"
          :hideBottom="true"
          :noDataLabel="$language('설비에 대한 점검항목이 없습니다.')"
          rowKey="minEquipmentTypeCheckItemId"
        >
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='customCol'">
              <component
                :is="imprComponent"
                :col="col"
                :props="props"
                :inputEditable="editable && result.checkStatusCd!=='MCSC000015'"
                :isImmShow="true"
                :requestContentsCols="requestContentsCols"
                tableKey="minEquipmentTypeCheckItemId"
                ibmTaskTypeCd="ITT0000055"
                ibmTaskUnderTypeCd="ITTU000085"
                @imprChange="imprChange"
              />
            </template>
          </template>
          <template v-if="isMultiple" slot="table-chip">
            <q-btn-group outline >
              <q-chip outline square>
                <q-avatar icon="push_pin" color="blue-6" text-color="white" /> 
                <!-- 설비명(관리번호) -->
                {{ `${$language('설비명(관리번호)')} : ${result.equipmentName}(${result.equipmentCd})`}}
              </q-chip>
            </q-btn-group>
          </template>
          <template slot="table-button">
            <q-btn-group outline >
              <!-- 출력 -->
              <c-btn label="출력" icon="print" @btnClicked="btnResult" />
                  <c-btn
                v-if="isMultiple && result.equipmentResultList.length > 0 && editable && result.checkStatusCd!=='MCSC000015'"
                :url="updateItemUrl"
                :isSubmit="isSave2"
                :param="result.equipmentResultList"
                mappingType="PUT"
                label="저장"
                icon="save"
                @beforeAction="saveItemInspection"
                @btnCallback="saveCallback" 
              />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>

import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-result-detail',

  props: {
    result: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentName: '',
        checkStatusCd: '',
        minEquipmentCheckId: '',
        equipmentResultList: [],
        checkDate: '',
      }),
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    research: {
      type: Object,
      default: () => ({
        impr: '',
        item: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      tab: 'resultItem',
      grid2: {
        columns: [
          {
            name: 'checkItemName',
            field: 'checkItemName',
            // 점검항목
            label: '점검항목',
            style: 'width:200px',
            // type: 'textarea',
            align: 'left',
            sortable: true
          },
          {
            name: 'checkResult',
            field: 'checkResult',
            // 점검결과
            label: '점검결과',
            align: 'left',
            style: 'width:200px',
            type: 'textarea',
            sortable: true
          },
          {
            name: 'checkWayName',
            field: 'checkWayName',
            // 검사방법
            label: '검사방법',
            align: 'center',
            style: 'width:100px',
            sortable: true
          },
          {
            name: 'judgmentResultTypeCd',
            field: 'judgmentResultTypeCd',
            // 적합/부적합
            label: '적합/부적합',
            align: 'center',
            style: 'width:130px',
            type: 'select',
            setHeader: true,
            comboItems: [
              { code: 'MCRC000005', codeName: '적합' },
              { code: 'MCRC000010', codeName: '부적합' },
              { code: 'MCRC000015', codeName: '대상외' },
            ],
            sortable: false,
          },
          {
            name: 'decisionBasis',
            field: 'decisionBasis',
            // 판정기준
            label: '판정기준',
            // type: 'textarea',
            style: 'width:200px',
            align: 'left',
            sortable: true
          },
          {
            name: 'checkCaution',
            field: 'checkCaution',
            // 점검시 안전사항
            label: '점검시 안전사항',
            style: 'width:200px',
            align: 'left',
            sortable: true
          },
          {
            name: 'customCol',
            field: 'customCol',
            // 개선/진행상태
            label: '개선/진행상태',
            align: 'center',
            style: 'width:150px',
            type: 'custom',
            sortable: false
          },
        ],
        data: [],
        height: '500px',
      },
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      chkResultItems: [],
      chkRsltYnItems: [
        { code: 'Y', codeName: '완료' },
        { code: 'N', codeName: '미완료' },
      ],
      title: '',
      insertUrl: '',
      updateUrl: '',
      updateItemUrl: '',
      getUrl: '',
      isSave: false,
      isSave2: false,
      updateMode: false,
      equipmentCdName: '',
      printUrl: '',
      colorItems: [],
      requestImprRow: null,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['checkItemName'];
    },
    // 개선요청 Component
    imprComponent() {
      let components = () => import(`${'@/pages/common/ibm/tableImpr.vue'}`);
      return components;
    },
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.updateItemUrl = transactionConfig.sop.min.equipment.result.updateItem.url;
      this.printUrl = selectConfig.sop.min.equipment.maintain.print2.url;
      // list setting
    },
    saveItemInspection() {
      if (this.$comm.validTable(this.grid2.columns, this.result.equipmentResultList)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$_.forEach(this.result.equipmentResultList, _item => {
              _item.minEquipmentCheckId = this.result.minEquipmentCheckId;
            })
            this.isSave2 = !this.isSave2;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    btnResult() {
      let thisVue = this;
      this.$http.url = this.printUrl
      this.$http.type = 'GET';
      this.$http.param = {
        checkDate: this.result.checkDate,
        checkDeptName: this.result.checkDeptName,
        checkUserName: this.result.checkUserName,
        plantName: this.result.plantName,
        equipmentCd: this.result.equipmentCd,
        minEquipmentCheckId: this.result.minEquipmentCheckId
      };
      this.$http.request(
        _result => {
          let fileOrgNm = this.result.equipmentName + '(' + this.result.checkDate + ')' + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
    // 개선창 닫기 후
    imprChange() {
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>
